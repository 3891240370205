import { Link } from "gatsby"
import * as React from "react"
//import { Link } from "gatsby"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"

const PoliticaCookiesPage = () => (
  <>
    <div className="artigos-head">
      <div className="container">
        <span className="title">Política de Cookies</span>
      </div>
    </div>
    <section className="just-text-section">
      <div className="container">
        <p className="subtitle">INFORMAÇÕES GERAIS</p>
        <p>
          O presente <i>website</i> utiliza <i>cookies</i> que permitem melhorar
          o desempenho e a experiência de navegação do utilizador e/ou
          visitante.
        </p>
        <p>
          Qualquer navegador de internet (<i>browser</i>) permite ao utilizador
          e/ou visitante aceitar, recusar ou apagar <i>cookies</i>, através das
          definições do próprio navegador.
        </p>
        <br />
        <p className="subtitle">O QUE É UM COOKIE?</p>
        <p>
          Os <i>cookies</i> são ficheiros de texto com informações relevantes
          que o browser processa, quando um site é visitado por um utilizador.
        </p>
        <p>
          A colocação de <i>cookies</i> ajuda o site a reconhecer o dispositivo
          (tablet, desktop, telefone móvel, etc.) do utilizador sempre que acede
          a este site. Os <i>cookies</i> retêm apenas informação relacionada com
          as preferências do utilizador, não revelando informações pessoais.
        </p>
        <br />
        <p className="subtitle">TIPOS DE COOKIES</p>
        Existem vários tipos de <i>cookies</i> com caraterísticas e funções
        diferentes. São eles:
        <ul>
          <li>
            <b>Cookies essenciais:</b> São importantes para aceder a áreas
            específicas do site permitindo uma navegação correta.
          </li>
          <li>
            <b>Cookies de funcionalidade:</b> Os <i>cookies</i> de
            funcionalidade permitem que as preferências do utilizador sejam
            guardadas quando visita o site. Desta forma, não é necessário
            personalizar o site sempre que o visita.
          </li>
          <li>
            <b>Cookies analíticos:</b> Este tipo de <i>cookies</i> permite
            analisar a forma como os utilizadores utilizam o site, conhecer as
            páginas mais populares e monitorizar o desempenho do site,
            possibilitando perceber possíveis mensagens de erro no site. Nunca
            serão reveladas informações de cariz pessoal.
          </li>
        </ul>
        <br />
        <p className="subtitle">
          COOKIES UTILIZADOS E RESPETIVAS FUNCIONALIDADES
        </p>
        <table className="tabela-cookies">
          <thead>
            <tr>
              <th>Cookie</th>
              <th>Nome</th>
              <th>Finalidade</th>
              <th>Tipo</th>
              <th>Duração</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Google Analytics</td>
              <td>_gat</td>
              <td>
                Este cookie normalmente é escrito no browser na primeira visita.
                Se o cookie tiver sido eliminado pelo operador do browser e o
                browser visitar posteriormente o pwc.com, o novo cookie _gat
                será gravado com um ID único exclusivo. Na maioria dos casos
                este cookie é usado para determinar os visitantes únicos do
                pwc.com e é atualizado com cada visualização de página. Além
                disso, este cookie é fornecido com um ID exclusivo que o Google
                Analytics usa para garantir a validade e a acessibilidade do
                cookie como uma medida de segurança extra.{" "}
              </td>
              <td>Interno e de sessão</td>
              <td>1 minuto</td>
            </tr>
            <tr>
              <td>Google Analytics </td>
              <td>_gid</td>
              <td>
                Este cookie armazena um identificador de sessão de um utilizador
                exclusivo.
              </td>
              <td>Interno e de terceiro</td>
              <td>24 horas</td>
            </tr>
            <tr>
              <td>Google Analytics </td>
              <td>_ga</td>
              <td>
                Este cookie normalmente é escrito no browser na primeira visita.
                Se o cookie tiver sido eliminado pelo operador do browser e o
                browser visitar posteriormente o pwc.com, o novo cookie _ga será
                gravado com um ID único exclusivo. Na maioria dos casos este
                cookie é usado para determinar os visitantes únicos do pwc.com e
                é atualizado com cada visualização de página. Além disso, este
                cookie é fornecido com um ID exclusivo que o Google Analytics
                usa para garantir a validade e a acessibilidade do cookie como
                uma medida de segurança extra.
              </td>
              <td>Interno e de terceiro (persistentes)</td>
              <td>2 anos</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </>
)

export const Head = () => <Seo title="Política de Cookies" />

export default PoliticaCookiesPage
